import { getImage } from 'gatsby-plugin-image';
import { graphql } from 'gatsby';
import content from 'content/content.json';
import Hero from 'components/shared/Hero';
import imageList from '../config/images';
import Layout from 'components/Layout';
import React from 'react';
import seo from 'config/seo.json';

export default class Franchise extends React.Component {
	render() {
		return (
			<Layout
				title={seo.franchisees.title}
				description={seo.franchisees.description}
			>
				<Hero
					alt={imageList.franchise.alt}
					buttonText={content.pages.franchise.hero.button}
					buttonUrl="/contact"
					content={content.pages.franchise.hero.content}
					image={getImage(this.props.data.file)}
					isExternalLink={false}
					paddingBottom="12"
					title={content.pages.franchise.hero.title}
				/>
				<section className="grid grid-cols-1 lg:grid-cols-3 gap-12 max-w-7xl mx-auto px-8 lg:px-16 py-12">
					<header className="">
						<h3 className="font-semibold mb-8 text-3xl">
							{content.pages.franchise.difference.title}
						</h3>
						<p>{content.pages.franchise.difference.subtitle}</p>
					</header>
					<div className="lg:col-span-2">
						<p className="mb-4">
							{content.pages.franchise.difference.content}
						</p>
						<p className="mb-4">
							{content.pages.franchise.difference.contentTwo}
						</p>
						<h4 className="mb-4 text-lg">
							{
								content.pages.franchise.difference
									.contentThreeTitle
							}
						</h4>
						<p className="mb-4">
							{content.pages.franchise.difference.contentThree}
						</p>
						<p className="mb-4">
							{content.pages.franchise.difference.contentFour}
						</p>
						<small>
							{content.pages.franchise.difference.terms}
						</small>
					</div>
				</section>
			</Layout>
		);
	}
}

export const pageQuery = graphql`
	{
		file(relativePath: { eq: "hero-franchise.png" }) {
			id
			childImageSharp {
				gatsbyImageData
			}
		}
	}
`;
